import React from 'react'
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import moment from 'moment'
import Image from '../components/image'
import Layout from '../components/layout'
import Video from '../components/VideoOriginal'
import FacebookIcon from '../images/facebook-icon.svg'
import TwitterIcon from '../images/twitter-icon.svg'
import LinkedinIcon from '../images/linkedin-icon.svg'

export const query = graphql`
  query ArticleQuery($uid: String!, $lang: String!) {

    ...HeaderNavFragment
    ...FooterNavFragment
    ...SettingsFragment

    prismic {
      allArticles(uid: $uid, lang: $lang) {
        edges {
          node {
            title
            date
            meta_title
            meta_description
            time_to_read
            hero_image
            summary
            content_width

            social {
                ... on PRISMIC_ArticleSocialGeneral_card {
                    label
                    type
                    primary {
                        description
                        image
                        title
                    }
                }
                ... on PRISMIC_ArticleSocialTwitter_card {
                    label
                    type
                    primary {
                        card_type
                        description
                        image
                        title
                        twitter_handle
                    }
                }
            }

            _meta {
                uid
                lang
                type
                tags
                alternateLanguages {
                    id
                    lang
                }
            }

            body {
              ... on PRISMIC_ArticleBodyContent {
                type
                label
                primary {
                  content
                  content_width
                }
              }
              ... on PRISMIC_ArticleBodyImage_with_caption {
                type
                label
                primary {
                    image
                    caption
                    image_size
                    content_width
                }
              }
              ... on PRISMIC_ArticleBodyQuote {
                type
                label
                primary {
                  quote
                  author
                  content_width
                }
              }
              ... on PRISMIC_ArticleBodyButton {
                type
                label
                primary {
                    link {
                        _linkType
                        ... on PRISMIC__Document {
                          _meta {
                            id
                            uid
                            lang
                            type
                          }
                        }
                        ... on PRISMIC__ExternalLink {
                          url
                        }
                    }
                    link_text
                    align
                }
              }
              ... on PRISMIC_ArticleBodyVideo {
                type
                label
                primary {
                  video_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  video_caption
                  video_thumb
                  bg_color
                  content_width
                }
              }
            }

            person {
              _linkType 
              ... on PRISMIC_Person {
                name
                role
                image
              }
            }
          }
        }
      }
    }
  }
`

export default ({ data, location }) => {

  let doc = data.prismic.allArticles.edges.slice(0, 1).pop();
  if ( !doc ) return null;

  doc = doc.node;

  const layoutParams = {
    doc: doc, 
    headerNav: data.header.navigation,
    secondaryNav: data.secondaryNav.navigation,
    footerNav: data.footer.navigation, 
    settings: data.settings.global 
  }

  let pageTitle = RichText.asText(doc.title);
  let domain = `https://www.meniga.is`;
  let currentPath = location.pathname;
  let twitterLink = `https://twitter.com/intent/tweet?text=` + pageTitle + `&url=` + domain + currentPath;
  let facebookLink = `https://www.facebook.com/sharer/sharer.php?u=` + domain + currentPath;
  let linkedinLink = 'http://www.linkedin.com/shareArticle?mini=true&url=' + domain + currentPath;

  const getColumnClass = (width, def) => {
    const map = {
      'Default (2/3)': '2-3',
      '3/4': '3-4',
    }

    if ( width === 'Inherit' ) {
      return map[def];
    }
    return map[width];
  }

  let pageColumnWidth = getColumnClass(doc.content_width);

  return (
    <Layout {...layoutParams}>
      <div className="blog-article">
        <div className="c-article-header">
          <div className="o-container">
            <div className="pure-container">
              <div className={'pure-u-1' + (pageColumnWidth ? ' pure-u-md-' + pageColumnWidth : '') }>
                <div data-line="under">
                  <div className="o-header">
                    <h1>{RichText.asText(doc.title)}</h1>
                  </div>
                </div>

                {doc.summary ?
                  <p className="c-article-summary">{RichText.render(doc.summary)}</p>
                  : <></>
                }

                <div className="c-article-meta">
                  <div className="c-author-box">
                    {doc.person && doc.person.image ?
                      <div class="c-author-box--image" style={{ backgroundImage: `url(${doc.person.image.url})` }}></div>
                      : <></>
                    }

                    <div>
                      {doc.person ?
                        <p className="c-author-box--author">{doc.person.name}</p>
                        : <></>
                      }

                      <p className="c-author-box--details">{moment(doc.date).format('DD MMM YYYY')}</p>
                    </div>
                  </div>

                  <div className="c-article-footer--social">
                    <a target="_blank" href={twitterLink} rel="noreferrer"><img src={TwitterIcon} alt="twitter-icon"></img></a>
                    <a target="_blank" href={facebookLink} rel="noreferrer"><img src={FacebookIcon} alt="facebook-icon"></img>  </a>
                    <a target="_blank" href={linkedinLink} rel="noreferrer"><img src={LinkedinIcon} alt="linkedin-icon"></img>  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {doc.hero_image ? (
            <div className="c-article--hero-image">
              <Image url={doc.hero_image.url}></Image>
            </div>
          ) : <></>
        }

        <div className="post-body">
          {doc.body && doc.body.map(element => {

            let html = <></>;
            let className = '';
            let fullWidth = false;

            if ( element.type === 'content' ) {
              className = 'content';
              html = (<>{RichText.render(element.primary.content)}</>);

            } else if ( element.type === 'image_with_caption' ) {
              className = 'image';
              html = ( 
                <div className={'o-image' + (element.primary.image_size ? ' image-' + element.primary.image_size.substr(0,1).toLowerCase() : '')}>
                  <Image image={element.primary.image} caption={element.primary.caption}></Image>
                </div>
              );

            } else if ( element.type === 'quote' ) {
              className = 'quote';
              fullWidth = true;
              html = (
                <div data-line="left" className="c-article-quote">
                  <div className="o-header-quote">
                    <h1>{RichText.render(element.primary.quote)}</h1>
                  </div>

                  {element.primary.author ? <div className="quote-author">{element.primary.author} </div> : <></>}
                </div>
              );

            } else if ( element.type === 'video' ) {
              className = 'video';
              html = (<Video {...element}></Video>);

            } else if ( element.type === 'button' ) {
              className = 'button';
              html = (<Link {...element.primary.link} isButton="true">{element.primary.link_text}</Link>);
            }

            let columnWidth = getColumnClass(element.primary.content_width, doc.content_width);

            return (
              <section className={'body-section body-section--' + className}>
                <div className="o-container">
                  <div className="pure-container">
                    <div className={'pure-u-1' + (columnWidth ? ' pure-u-md-' + columnWidth : '') }>
                      {html}
                    </div>
                  </div>
                </div>
              </section>
            )
          })}
        </div>

        {doc._meta.tags.length ? (
            <div className="c-article-footer">
              <div className="o-container">
                <hr/>

                {doc._meta.tags.length ? (
                    <div className="c-article-footer--tags">
                      {doc._meta.tags.map(tag => (
                          <span className="c-article-footer--tag">{tag}</span>
                      ))}
                    </div>
                  ) : <></>
                }

                <hr/>
              </div>
            </div>
          ) : <></>
        }
      </div>
    </Layout>
  )
}
