import { RichText } from 'prismic-reactjs'
import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import './index.scss'

export default class Video extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentVideo: false
        }

        if ( props.fields && props.fields.length > 0 ) {
            this.state.currentVideo = props.fields[0]
        }
    }

    switchVideo(index) {
        this.setState({ 'currentVideo': this.props.fields[index] })
    }

    render() {

        const videoConfig = {
            youtube: {
                playerVars: { showinfo: 0, modestbranding: 1 },
                preload: "true",
            }
        }

        const videoCaption = this.props.primary.video_caption && RichText.asText(this.props.primary.video_caption) ?
            <div class="video-caption">{RichText.render(this.props.primary.video_caption)}</div>
            : <></>

        return (
            <>

                {(this.props.title && RichText.asText(this.props.title)) ? (
                        <div className="pure-g">
                            <div className="pure-u-1 pure-u-md-1">
                                <div class="o-header">
                                    <div class="o-title">{RichText.render(this.props.primary.title)}</div>
                                </div>
                            </div>
                        </div>
                    ) : <></>
                }


                {(this.props.primary.video_thumb && this.props.primary.video_link) ? (
                        <div className="pure-g">
                            <div className="pure-u-1">
                                <div className="video-container">
                                    <ReactPlayer className='react-player' controls="true" width="100%" height="100%" config={videoConfig} playing="true" light={this.props.primary.video_thumb.url} url={this.props.primary.video_link.url}></ReactPlayer>
                                </div>
                                {videoCaption}
                            </div>
                        </div>
                    ) : <></>
                }

                {this.props.fields && this.props.fields.length > 0 && this.props.fields[0].video_thumb && this.props.fields[0].video_link ? (
                        <div className="pure-g">
                            <div className="pure-u-1 pure-u-md-19-24">
                                <div className="video-container">
                                    {this.state.currentVideo ? (
                                            <ReactPlayer className='react-player' controls="true" width="100%" height="100%" config={videoConfig} playing="true" light={this.state.currentVideo.video_thumb.url} url={this.state.currentVideo.video_link.url}></ReactPlayer>
                                        ) : <></>
                                    }
                                </div>
                            </div>

                            <div class="pure-u-1 pure-u-md-5-24">
                                <ul class="video-list">
                                    {this.props.fields && this.props.fields.map((item, index) => (
                                        <li className={[(item.video_thumb.url === this.state.currentVideo.video_thumb.url ? 'active' : '')]} onClick={() => this.switchVideo(index)}><img src={item.video_thumb.url} alt="video thumb" class="img-fluid"></img></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : <></>
                }
            </>
        )
    }
}
